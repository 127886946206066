import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Container } from 'react-bootstrap';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ProjectPage from './pages/ProjectPage';
import ProjectsPage from './pages/ProjectsPage';
import CertificatesPage from './pages/CertificatesPage';
import WorkPage from './pages/WorkPage';
import WorkHistoryPage from './pages/WorkHistoryPage';

function App() {
    return(
        <>
            <Container>
            <Header/>
                <Routes>
                    <Route exact path="/" element={<HomePage/>}/>
                    <Route exact path="/home" element={<HomePage/>}/>
                    <Route exact path="/projects" element={<ProjectsPage/>}/>
                    <Route exact path="/certificates" element={<CertificatesPage/>}/>
                    <Route path="/projects/:id" element={<ProjectPage/>}/>
                    <Route path="/work" element={<WorkHistoryPage/>}/>
                    <Route path="/work/:id" element={<WorkPage/>}/>
                    <Route path="*" element={<Navigate to="/404" replace/>}/>
                </Routes>
            </Container>
            <Footer/>
        </>
    );
}

export default App;