import React from 'react';
import { Container } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { setLocale } from '../redux/modules/locale';

const Footer = ({dispatch}) => {

    const locales = useSelector(state => state.locale);

    const getYear = () => {
        return new Date().getFullYear();
    };

    const handleLocaleClick = locale => {
        dispatch(setLocale(locale));
    };

    return(
        <div className="footer">
            <center>
                <p>
                    {locales['available'].map((item, index) => {
                        return <span key={index} onClick={() => handleLocaleClick(item['code'])}>
                            {item['code'] === locales['selected'] ? <b>[{item['name']}]</b> : `${item['name']}`}
                        </span>
                    })}
                </p>
                <p>{getYear()} © Nick Ruotsalainen</p>
            </center>
        </div>
    )
}

export default connect()(Footer);