import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import RouterNavLink from './RouterNavLink';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import EmptyNavbar from './EmptyNavbar';

function Header() {
   return(
    <EmptyNavbar>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"><img width="24" src="/images/menu-icon.svg"/></Navbar.Toggle>
      <Navbar.Collapse className="justify-content-end">
      <Nav className="ml-auto">
        <RouterNavLink to="home"><FormattedMessage id="home"/></RouterNavLink>
        <RouterNavLink to="work"><FormattedMessage id="work"/></RouterNavLink>
        <RouterNavLink to="projects"><FormattedMessage id="projects"/></RouterNavLink>
        <div className="socials">
            <Link target="_blank" to="https://www.github.com/nichnet"><img alt={<FormattedMessage id="github"/>} src="/images/github.svg"/></Link>
            <Link target="_blank" to="https://www.linkedin.com/in/nick-ruotsalainen-75573a119"><img alt={<FormattedMessage id="linkedin"/>} src="/images/linkedin.svg"/></Link>
        </div>
        </Nav>
     </Navbar.Collapse>
    </EmptyNavbar>
   );
}

export default Header;