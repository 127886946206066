import { Link, NavLink, useLocation } from 'react-router-dom';

function RouterNavLink({ to, children }) {

  let pathAfterLocale = useLocation().pathname.split('/')?.[2];

  if(!pathAfterLocale) {
    pathAfterLocale = 'home';
  }

  const isActive = to === pathAfterLocale;

  // Wrap the text in square brackets if active
  return (
    <NavLink className="nav-link" as={Link} to={to}>
      {isActive ? <b>[{children}]</b> : children}
    </NavLink>
  );
};

export default RouterNavLink;
