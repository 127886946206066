import { useLocation, useNavigate } from 'react-router-dom';
import * as types from './types';

export const loadLocale = () => {
  return async (dispatch) => {
    try {
      const availableLocales = [];
      let translations = {};

      // Fetch the list of available locales
      const response = await fetch('/locale/locale.json');
      const localeList = await response.json();
      availableLocales.push(...localeList);


      // Fetch translations for each locale
      await Promise.all(availableLocales.map(async (locale) => {
        const translationResponse = await fetch(`/locale/${locale['code']}.json`);
        const translationData = await translationResponse.json();
        translations[locale['code']] = translationData;
      }));

      let selected = localStorage.getItem('language');
      
      dispatch({
        type: types.LOAD_LOCALE_SUCCESS,
        payload: {
          available: availableLocales,
          selected: selected,
          translations: translations,
        },
      });

    } catch(err) {
      console.log(err);
      dispatch({
        type: types.LOAD_LOCALE_FAILURE,
      });
    }
  };
};

export const setLocale = (locale) => {
  return async (dispatch) => {
    try {
      localStorage.setItem('language', locale);

      dispatch({
        type: types.SET_LOCALE_SUCCESS,
        payload: locale,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: types.SET_LOCALE_FAILURE,
      });
    }
  };
};
