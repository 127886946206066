import * as types from './types';

const initialState = {
  selected: null,
  available: [],
  translations: {},
  loaded: false
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_LOCALE:
      return {
        ...state,
        translations: {},
      };
    case types.LOAD_LOCALE_SUCCESS:
      return {
        ...state,
        available: action.payload.available,
        translations: action.payload.translations,
        selected: action.payload.selected,
        loaded: true,
      };
    case types.LOAD_LOCALE_FAILURE:
      return {
        ...state,
      };
      case types.SET_LOCALE:
        return {
          ...state,
        };
      case types.SET_LOCALE_SUCCESS:
        return {
          ...state,
          selected: action.payload,
        };
      case types.SET_LOCALE_FAILURE:
        return {
          ...state,
        };
  default:
    return state;
  }
};

export default localeReducer;
