import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import * as constants from '../constants';
import { Link } from 'react-router-dom';

function EmptyNavbar({children}) {
   return(
        <Navbar expand="lg" className="ml-auto my-2">
        <Container>
          <Navbar.Brand><Link className="nav-link" to="../"><b>[{constants.title}]</b></Link></Navbar.Brand>
          {children}
        </Container>
      </Navbar>    
    )

}

export default EmptyNavbar;