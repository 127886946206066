import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown  from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { FormattedMessage } from 'react-intl';

function ProjectPage() {
    const { id } = useParams();

    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [markdownContent, setMarkdownContent] = useState('');

    function fetchProject(passedProject) {
        setProject(passedProject);

        if(passedProject) {
            fetch(`/data/projects/${passedProject.id}/index.md`)
            .then((response) => response.text())
            .then((data) => {
                    if(data.toLowerCase().startsWith("<!doctype") || data.length === 0) {
                        throw new Error("doc");
                    }
                    setMarkdownContent(data);
            })
            .catch((error) => {
                navigate('/404', { replace: true });
            });
        } else{ 
            navigate('/404', { replace: true });
        }  
    }

    useEffect(() => {
        fetch('/data/projects.json')
            .then((response) => response.json()) 
            .then((data) => {
                let singleProject = data.find(project => project.id === id);
                fetchProject(singleProject);
            })
            .catch((error) => {
                navigate('/404', { replace: true });
            });
      }, []);

    function getStack() {
        if(project?.stack) {
            return (<div className="mt-3">
                {project.stack.map(item => {
                    return (
                        <p className="tag">{item}</p>
                    )
                })}
            </div>);
        }
    }

    return(
        <Row>
            <center>
                <h1 className="section-header">{
                project ? project.title : ""}</h1>
            </center>
            <Col className="my-3" xs={12} md={{span:10, offset: 1}} lg={{span:8, offset: 2}}>
                <span className="dir-path"><Link to="../"><FormattedMessage id="home"/></Link><Link to="../projects"><FormattedMessage id="projects"/></Link>{id}</span>
                <Col className="box page">
                    { markdownContent ?
                    <>
                        <ReactMarkdown
                        children={markdownContent} rehypePlugins={[rehypeRaw]}
                        />
                        {getStack()}
                    </>: 
                    <Loader/>
                    }
                </Col>
            </Col>
        </Row>
    );
}

export default ProjectPage;