import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../components/Loader';
import ProjectCard from '../components/ProjectCard';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function ProjectsPage() {
    const [getProjects, setProjects] = useState(null);


    function listProjects(featured) {
        return getProjects.filter((proj) => {
            // Check if the proj object has the 'featured' property
            if (proj.hasOwnProperty('featured')) {
                // If featured is true, include proj where proj.featured is true
                if (featured === true) {
                    return proj.featured === true;
                } else {
                    // If featured is false, include proj where proj.featured is false or doesn't exist
                    return proj.featured === false || proj.featured === undefined;
                }
            } else {
                // If proj doesn't have the 'featured' property
                // Include it if featured is false
                return featured === false;
            }
        }).map((item, index) => {
            return (
                <Col xs={12} md={6} lg={4} key={index} className="">
                    <ProjectCard key={item.id} project={item} link={item.id}/>
                </Col>
            )
        })
    }

    useEffect(() => {
        fetch('/data/projects.json')
            .then((response) => response.json()) 
            .then((data) => setProjects(data))
            .catch((error) => {
                //
            });
    },[]);

    return(
        <Row>
            <center>
                <h1 className="section-header"><FormattedMessage id="projects"/></h1>
            </center>
            <Col className="my-3" xs={12} md={{span:10, offset: 1}} lg={{span:8, offset: 2}}>
                <span className="dir-path"><Link to="../"><FormattedMessage id="home"/></Link><FormattedMessage id="projects"/></span>
                
                <Row>
                    {
                        getProjects ? 
                        <>
                            <section id="featured">
                                <h1 className="section-header"><FormattedMessage id="featured"/></h1>
                                <Row>
                                    {listProjects(true)} 
                                </Row>
                            </section>
                            <section id="other">
                                <h1 className="section-header"><FormattedMessage id="other"/></h1>
                                <Row>
                                    {listProjects(false)}
                                </Row>
                            </section>
                        </>
                        :
                        <Loader/>
                    }
                </Row>
            </Col>
        </Row>
    );
}

export default ProjectsPage;