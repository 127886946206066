import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from './App';
import UnknownPage from './pages/UnknownPage';
import Cursor from './components/Cursor';
import ParticlesBg from 'particles-bg';
import { IntlProvider } from 'react-intl';
import Loader from './components/Loader';
import { connect, useSelector } from 'react-redux';
import { loadLocale, setLocale } from './redux/modules/locale';
import LanguageSelect from './pages/LanguageSelect';
//import ManifestManager from './components/ManifestManager';

function LocaleApp({dispatch, selected}) {

  const locale = useSelector(state => state.locale);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadLocale());
  }, []);

  useEffect(() => {
    if(locale.loaded) {
      const pathLocale = location.pathname.split('/')[1]; 
      const isAvailable = locale.available.filter((l) => l.code === pathLocale).length > 0;

      if(pathLocale !== locale.selected && isAvailable) {
        dispatch(setLocale(pathLocale));
      }
    }
  }, [locale?.loaded]);

  useEffect(() => {
    if(!locale.selected) {
      return;
    }
    const paths = location.pathname.split('/').filter((p) => p !== '');

    const isAvailable = locale.available.filter((l) => paths[0] === l['code']).length > 0;

    if(isAvailable) {
      paths[0] = locale.selected;
    } else {
      paths.unshift(locale.selected);
    }

    const newPathname = `/${paths.join('/')}`

    const newLocation = {
      ...location,
      pathname: newPathname
    };

    // Perform the navigation with replace: true to replace the current history entry
    navigate(newLocation, { replace: true });
  }, [locale.selected]);


  const [particlesCount, setParticlesCount] = useState(150);

  const handleWindowResize = () => {
      let width = document.body.clientWidth;
      // document.body.clientHeight;
      if(width <= 750) {
          setParticlesCount(50);
      } else if(width <= 1000) {
          setParticlesCount(75);
      } else if(width <= 1500) {
          setParticlesCount(100);
      }  else {
          setParticlesCount(150);
      }  
  };

  useEffect(() => {
      window.addEventListener('resize', handleWindowResize);
      // call handle resize by default to set the particle count on page load.
      handleWindowResize();
  }, []);


  const generateContent = () => {
    if(!locale?.loaded) {
      return <Loader/>
    }
  
    if(!locale?.selected) {
      return <LanguageSelect/>
    }
  
    /* TODO why cant i just pass the whole object to messages with both translations? */
    return (
      <IntlProvider locale={locale['selected']} messages={locale['translations'][selected]}>
        <Routes>
          {/* TODO const LOCALE_ROUTES = `/:locale(${LOCALES.join('|')})/*`; // This will match '/en' or '/ja' */}
          {locale.available.map((l) => {
            return <Route key={l['code']} path={`/${l['code']}/*`} element={<App/>}/>;
          })}
            <Route exact path="/" element={<Navigate to={`/${selected}`} replace/>}/> 
            <Route path="*" element={<Navigate to="/404" replace/>}/>
            <Route path="/404" element={<UnknownPage/>}/>
          </Routes>
          {/*TODO <ManifestManager locale={locale.selected}/>*/}
      </IntlProvider>
    );
  };

  return(
    <>
      {generateContent()}
      <ParticlesBg color="#696969" num={particlesCount} type="cobweb" bg={true}/>
      <Cursor/>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selected: state.locale.selected,
  };
};



export default connect(mapStateToProps)(LocaleApp);