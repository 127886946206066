import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../components/Loader';
import Timeline from '../components/Timeline';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function WorkHistoryPage() {
  const [getWorkHistory, setWorkHistory] = useState(null);

  useEffect(() => {
    fetch('/data/work_history.json')
      .then((response) => response.json())
      .then((data) => setWorkHistory(data))
      .catch((error) => {
        //
      });
  }, []);

  return (
    <Row>
      <center>
        <h1 className="section-header"><FormattedMessage id="work_history"/></h1>
      </center>
      <Col className="my-3" xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        <span className="dir-path"><Link to="../"><FormattedMessage id="home"/></Link><FormattedMessage id="work"/></span>
        <Col>
          {
            getWorkHistory ?
              <Timeline data={getWorkHistory} includeSections/>
              :
              <Loader />
          }
        </Col>
      </Col>
    </Row>
  );
}

export default WorkHistoryPage;