import React from 'react';

function HighlightWord({text}) {

    const style = {
        display: "inline-block",
        width: "auto"
    }

    return (
        <div className="box" style={style}>
            {text}
        </div>
    )
}

export default HighlightWord;