import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import EmptyNavbar from '../components/EmptyNavbar';
import Footer from '../components/Footer';

function UnknownPage() {

    const goBack = () => {
        window.history.back(); 
    };

    return(
        <Container>
            <EmptyNavbar/>
            <Col>
                <Row style={{margin: '96px 0', display:'flex', justifyContent:'center', textAlign:'center'}}>
                    <img alt="404" style={{width: "128px"}} src="/images/404.gif"/>
                </Row>
                <Row style={{margin: '96px 0', display:'flex', justifyContent:'center', textAlign:'center'}}>
                    <a style={{ cursor:'pointer' }} className="section-header" onClick={goBack}><FormattedMessage id="return"/></a>
                </Row>
            </Col>
            <Footer/>
        </Container>
    );
}


export default UnknownPage;