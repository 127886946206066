import React, { useEffect, useState } from 'react';
import './Cursor.css';

function Cursor() {
    
    const SIZE = 30;

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    // Update cursor position when the mouse moves
    const handleMouseMove = (e) => {
        if( e.clientX < window.innerWidth - (SIZE * 1.5) &&
            e.clientY < window.innerHeight - (SIZE * 1.5)
           ) {
                setCursorPosition({ x: e.clientX + window.scrollX, y: e.clientY + window.scrollY });
        }
    };

    useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('mousemove', handleMouseMove);
    };
    }, []);

    return (
        <div className="cursor-container">
            <div className="cursor" style={{ width: SIZE, height: SIZE, left: cursorPosition.x, top: cursorPosition.y }}></div>
        </div>
    );
}


export default Cursor;