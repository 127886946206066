import React from 'react';
import './Timeline.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';

const Timeline = ({data, max, includeSections, itemLinkPrefix}) => {

  const generateTimelineDate = date => {
    return (
      <Col key={date} className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="font-weight-bold"><b>
          <FormattedDate
            value={moment(date, "MM/YYYY").toDate()}
            year="numeric"
            month="numeric"
          />
          </b></div>
      </Col>
    );
  };

  const generatePath = (index, isLeft) => {
    return(
      //path between 1-2
      <Row key={`line_${index}`} className="timeline">
        <Col className="col-2">
          <div className={"corner " + (isLeft ? "top-right" : "right-bottom")}></div>
        </Col>
        <Col className="col-8">
          <hr className="horizontal"/>
        </Col>
        <Col className="col-2">
          <div className={"corner " + (isLeft ? "left-bottom" : "top-left")}></div>
        </Col>
      </Row>
     );
  }

  const hiddenTimelineItems = data.length - max - 1;

  const generateTimelineItem = (index, item) => {
    return (
      <div key={index}>
        <Row key={index} className={"align-items-center d-flex "+ index % 2 === 0 ? "justify-content-end" : ""}>
          {index % 2 === 0 && (generateTimelineDate(item.date_from))}
          <Col className={"test col-6 " + index % 2 === 0 ? "text-right" : ""}>
            <p><b>{item.role}</b></p>
            <p><u><i>{item.company}</i></u></p>
            <p>{item.short_text}</p>
            <Link className="link" to={itemLinkPrefix ? `${itemLinkPrefix}/${item.id}` : item.id}><FormattedMessage id="read_more"/></Link>
          </Col>
          {index % 2 !== 0 && (generateTimelineDate(item.date_from))}
        </Row>
        {/* dont include a path on the last entry */}
        {index < data.length - 1 && (generatePath(index, index % 2 === 0))} 
      </div>
    )
  };

  return (
    <Container className="box px-4 pb-4" xs={12} md={6}>
      <Col xs={12} md={{span:8, offset: 2}}>
        {data.slice(0, max ? max + 1 : data.length).map((item, index) => {
          //first section
          if(includeSections) {
            return (
              <section key={`section_${index}`} id={`${index + 1}`}>
                {generateTimelineItem(index, item)}
              </section>
            );
          } else {
            return generateTimelineItem(index, item);
          }
        })}
      </Col>
      {max && data.length > max && (
        <center style={{marginTop: '48px'}}>
          <Link to={`work#${hiddenTimelineItems}`}><FormattedMessage id="view_x_more_roles" values={{count:hiddenTimelineItems}}/></Link>
        </center>
      )}
    </Container>
  );
};

export default Timeline;