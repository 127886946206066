import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { FormattedMessage } from 'react-intl';

function WorkPage() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [getWorkHistory, setWorkHistory] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');

  function fetchWorkHistory(passedWork) {
    setWorkHistory(passedWork);

    if (passedWork) {
      fetch(`/data/work/${passedWork.id}/index.md`)
        .then((response) => response.text())
        .then((data) => {
          if (data.toLowerCase().startsWith("<!doctype") || data.length === 0) {
            throw new Error("doc");
          }
          setMarkdownContent(data);
        })
        .catch((error) => {
          navigate('/404', { replace: true });
        });
    } else {
      navigate('/404', { replace: true });
    }
  }

  useEffect(() => {
    fetch('/data/work_history.json')
      .then((response) => response.json())
      .then((data) => {
        let singleWork = data.find(work => work.id === id);
        fetchWorkHistory(singleWork);
      })
      .catch((error) => {
        navigate('/404', { replace: true });
      });
  }, []);

  function getStack() {
    if (getWorkHistory?.stack) {
      return (<div className="mb-3">
        {getWorkHistory.stack.map(item => {
          return (
            <p key={item} className="tag">{item}</p>
          )
        })}
      </div>);
    }
  }

  return (
    <Row>
      <center>
        <h1 className="section-header">{
          getWorkHistory ? getWorkHistory.role + ", " + getWorkHistory.company : ""}</h1>
      </center>
      <Col className="my-3" xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        <span className="dir-path"><Link to="../"><FormattedMessage id="home"/></Link><Link to="../work"><FormattedMessage id="work"/></Link>{id}</span>
        <Col className="box page">
          {markdownContent ?
            <>
              <div className="mb-3">
                <p>{getWorkHistory.role}</p>
                <p><i><u>{getWorkHistory.company}</u>, {getWorkHistory.date_from} - {getWorkHistory.date_until}</i></p>
              </div>
              {getStack()}
              <ReactMarkdown
                children={markdownContent} rehypePlugins={[rehypeRaw]}
              />
            </>
            :
            <Loader />
          }

        </Col>
      </Col>
    </Row>
  );
}

export default WorkPage;