import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import HighlightWord from '../components/HighlightWord';
import Timeline from '../components/Timeline';
import ProjectCard from '../components/ProjectCard';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

function HomePage() {

  const selectedLocale = useSelector(state => state.locale.selected);

  const [getAbout, setAbout] = useState(null);
  const [getEducation, setEducation] = useState(null);
  const [getQualifications, setQualifications] = useState(null);
  const [getCertificatesCount, setCertificatesCount] = useState(0);
  const [getProjects, setProjects] = useState(null);
  const [getWorkHistory, setWorkHistory] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  function fetchProjects() {
    return fetch('/data/projects.json')
      .then((response) => response.json())
      .then((data) => setProjects(data))
      .catch((error) => {
        //                
      });
  }

  function fetchCertificatesCount() {
    return fetch('/data/certificates.json')
      .then((response) => response.json())
      .then((data) => setCertificatesCount(data.length))
      .catch((error) => {
        //                
      });
  }
  
  function fetchAbout() {
    return fetch('/data/about.json')
    .then((response) => response.json())
    .then((data) => setAbout(data))
      .catch((error) => {
        //                
      });
  }

  function fetchEducation() {
    return fetch('/data/education.json')
    .then((response) => response.json())
    .then((data) => setEducation(data))
    .catch((error) => {
      //                
    });
  }
  
  function fetchQualifications() {
    return fetch('/data/qualifications.json')
    .then((response) => response.json())
    .then((data) => setQualifications(data))
    .catch((error) => {
      //                
      });
    }
    
    function fetchWorkHistory() {
      return fetch('/data/work_history.json')
        .then((response) => response.json())
        .then((data) => setWorkHistory(data))
        .catch((error) => {
          //     
        });
    }

    useEffect(() => {
      setIsLoading(true);

    Promise.all([
      fetchAbout(),
      fetchEducation(),
      fetchQualifications(),
      fetchCertificatesCount(),
      fetchProjects(),
      fetchWorkHistory(),
    ]).then(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }).catch((error) => {
      //
      setIsLoading(false);
    });
  }, []);

  const generateDataComponents = () => {
    return (
      <>
        <Col>
          {getAbout && (
            <Row>
              <Col style={{ paddingRight: 0, marginRight: 0 }} className="d-flex justify-content-center align-items-center">
                <Row>
                  <center>
                    <Col xs={12} md={7} lg={9}>
                      <img className="profile" src="/images/profile_bw.png" />
                      <p className="title" dangerouslySetInnerHTML={{__html: getAbout[selectedLocale].title}}/>
                      <p>{getAbout[selectedLocale].role}, {getAbout[selectedLocale].location}</p>
                    </Col>
                  </center>
                </Row>
              </Col>
              <Col className="section">
                <HighlightWord text={getAbout[selectedLocale].description} />
              </Col>
            </Row>
          )}
          <br />

          {getProjects && (
            <section id="projects">
              <Row>
                <h1 className="section-header"><FormattedMessage id="featured_projects"/></h1>
                  {getProjects.filter((proj => proj.featured)).map((item, index) => {
                    return (
                      <Col xs={12} md={6} lg={3} key={index} className="">
                        <ProjectCard key={item.id} project={item} link={`projects/${item.id}`} />
                      </Col>
                    )
                  })}
                  {getProjects?.length > 0 && (
                    <center>
                      <Link to="projects"><FormattedMessage id="view_all_x_projects" values={{count: getProjects ? getProjects.length : 0}}/></Link>
                    </center>
                  )}
              </Row>
            </section>
          )}

          <section id="qualifications">
            <Row>
              {getEducation && (
                <Col xs={12} md={6} >
                  <h1 className="section-header"><FormattedMessage id="education"/></h1>
                  <div className="box">
                    <ul>
                      {getEducation.map((item, index) => {
                        return (<li key={index} dangerouslySetInnerHTML={{ __html: item }} />)
                      })}
                      {getCertificatesCount > 0 && (
                        <li key="certificates"><Link to="certificates"><FormattedMessage id="view_all_x_certificates" values={{count:getCertificatesCount}}/></Link></li>
                      )}
                    </ul>
                  </div>
                </Col>
              )}
              {getQualifications && (
                <Col xs={12} md={6}>
                  <h1 className="section-header"><FormattedMessage id="goals"/></h1>
                  <div className="box">
                    <ul>
                      {getQualifications.map((item, index) => {
                        return (<li key={index} dangerouslySetInnerHTML={{ __html: item }} />)
                      })}
                    </ul>
                  </div>
                </Col>
              )}
            </Row>
          </section>
        </Col>
        {getWorkHistory && (
          <section id="work">
            <h1 className="section-header"><FormattedMessage id="work_history"/></h1>
            <Timeline data={getWorkHistory} max={2} itemLinkPrefix="work" />
          </section>
        )}
      </>
    );
  };

  return (
    <>
        {isLoading ? 
          <Col style={{ minHeight: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Loader/>
          </Col>
        :
          generateDataComponents()
        }
      </>
  )
}

export default HomePage;