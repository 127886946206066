import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect, useSelector } from "react-redux";
import { setLocale } from '../redux/modules/locale';
import EmptyNavbar from '../components/EmptyNavbar';
//import ManifestManager from '../components/ManifestManager';

function LanguageSelect({dispatch}) {

  const availableLocales = useSelector(state => state.locale.available);

  const handleLocaleClick = (locale) => {
    dispatch(setLocale(locale));
  }

  return (
    <Container>
      <EmptyNavbar/>
      <Col xs={{span:6, offset: 3}} md={{span:4, offset:4}} className='mt-4 pt-4'>
          {availableLocales.map((item, index) => {
            return (
              <Row style={{cursor: 'pointer', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center' }} className="my-4 box" key={index} onClick={() => handleLocaleClick(item.code)}>
                <p>{item.name}</p>
              </Row>
            )
          })}
      </Col>
      {/*TODO <ManifestManager locale={availableLocales[0]['code']}/>*/}
    </Container>
  );
}

export default connect()(LanguageSelect);