import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../components/Loader';
import ProjectCard from '../components/ProjectCard';
import { Link } from 'react-router-dom';
function CertificatesPage() {

    const [getCertificates, setCertificates] = useState(null);

    useEffect(() => {
        fetch('/data/certificates.json')
            .then((response) => response.json())
            .then((data) => setCertificates(data))
            .catch((error) => {
                //
            });
    },[]);

    return(
        <Row>
            <center>
                <h1 className="section-header">certificates</h1>
            </center>
            <Col className="my-3" xs={12} md={{span:10, offset: 1}} lg={{span:8, offset: 2}}>
                <span className="dir-path"><Link to="../">home</Link>certificates</span>
                <Row>
                {
                getCertificates ? 
                getCertificates.map((item, index) => {
                    return (
                        <Col xs={12} md={6} lg={3} key={index} className="">
                            <ProjectCard project={item}/>
                        </Col>
                    )
                })
                :
                <Loader/>
            }
                </Row>
            </Col>
        </Row>
    );
}


export default CertificatesPage;