import React from 'react';
import { useNavigate } from 'react-router-dom';

function ProjectCard({project, link}) {

    const navigate = useNavigate();

    const onLinkClick = () => {
        if(project.external_link) {
            window.open(project.external_link, '_blank');
        } else if(link) {
            navigate(link);
        }
    }

    return(
        <div onClick={onLinkClick} className="xcard box mb-3">
            <article>
                <p ><b>{project.title}</b></p>
                <p id="description">{project.description}</p>
            </article>
        </div>
    );
}

export default ProjectCard;